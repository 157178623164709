@import "palettes";
@import "theme";
@import "mixins";

form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
